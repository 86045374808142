import {authService} from '@talygen/authservice';
export default async function  initAuthService(retunrUrl) {
  if(await authService.isLoggedIn()) return;
  authService.authenticate(retunrUrl)
    .then(() => {
      // If the user successfully logs in, redirect them to the home page
      
      // if (window.location.hash) {
      //   window.location.hash = decodeURIComponent(window.location.hash).replace('#', '?');
      //   }
      //   window.location.replace("/CRM/index");
    })
    .catch((error) => {
      console.log("Error during login:", error);
    });   
}

