export function prefix(location, prefixes) {
  const pathname =location.pathname;
  const count = [...(pathname.match(/\//g) || [])].length
if(count>=2){
  return prefixes.some(
    prefix => location.href.indexOf(`${location.origin}/${prefix}/`) !== -1
  );
}else{
  return prefixes.some(
    prefix => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}
  }